










import { FormField } from '@/app_code/Forms'
import { BlogPostStatus, BlogPostType, CreateBlogModel } from 'truemarket-modules/src/models/api/blogs'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

import Form from '@/components/UI/Elements/Forms/Form.vue'

@Component
export default class CreateBlogPost extends Vue {
  fields: FormField[] = [];

  mounted () {
    this.fields = [
      new FormField({
        Key: 'ExpirationDate',
        Type: 'date',
        Label: 'Expiration Date (optional)'
      }),
      new FormField({
        Key: 'Heading',
        Type: 'text',
        Label: 'Heading',
        Placeholder: ''
      }),
      new FormField({
        Key: 'Subheading',
        Label: 'Subheading',
        Type: 'text'
      }),
      new FormField({
        Key: 'Content',
        Label: 'Content',
        Type: 'richtext',
        Value: 'Hello, World!'
      }),
      new FormField({
        Key: 'ExternalLink1',
        Type: 'url',
        Label: 'External Link 1'
      }),
      new FormField({
        Key: 'ExternalLink2',
        Type: 'url',
        Label: 'External Link 2'
      })
    ]
  }

  savePost () {
    const model = (this.$refs.form as Form).GetValues().BuildModel<CreateBlogModel>()

    model.BusinessBranchId = this.$store.state.identity.BusinessBranch.BusinessBranchId
    model.Status = BlogPostStatus.Draft
    model.Type = BlogPostType.Blog

    Services.API.Blogs.CreateBlog(model).then((blog) => {
      this.$router.replace(`/dashboard/blogs/${blog.BlogId}`)
    })
  }
}
